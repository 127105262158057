/* ContactForm.css */

.contact-form {
  color: #fff;
  padding: 1.25rem;
  border-radius: 0.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 95%;
}

.contact-form h2 {
  text-align: start;
  margin-bottom: 1.25rem;
  font-size: 2.5rem;
  margin-left: 5%;
}

.container.form-main-div{
  max-width: 95%;
}

.contact-form h2 span {
  color: #2196f3;
  text-align: start;
  margin-bottom: 1.25rem;
  font-size: 2.5rem;
}

.contact-form form {
  display: flex;
  justify-content: space-between;
}
.form-main-div {
  
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.left-column {
  width: 45%;
}



.right-column {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 1rem 1rem;
}

.left-column {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 0 0;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: space-between;
}

.contact-input input {
  padding: 0.625rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.phone-input,
.email-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.name-input,
.surname-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 49%;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100% !important;
  padding: 0.625rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  resize: none;
}

.form-main-div input,
.form-main-div textarea,
.form-main-div button ,
.submit-button.home-button button{
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}

.submit-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.submit-button button {
  align-self: flex-end;
  background-color: #2196f3;
  color: #fff;
  padding: 0.625rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  width: 10%;
  margin-right: 4%;
}

.submit-button button:hover {
  background-color: #1976d2;
}
