.footer-info-box {
    padding: 17.18rem 0 0px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-info-box {
      padding-top: 21.875rem;
      padding-bottom: 9.375rem;
    }
  }
  
  @media only screen and (min-width: 760.5rem) and (max-width: 991px) {
    .footer-info-box {
      padding-top: 12.5rem;
      padding-bottom: 11.25rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .footer-info-box {
      padding-top: 11.25rem;
      padding-bottom: 6.25rem;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .footer-info-box {
      padding-top: 9.375rem;
      padding-bottom: 4.375rem;
    }
  }
  
  .footer-info-box .title {
    font-size: 1.5rem;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }
  
  @media only screen and (max-width: 767px) {
    .footer-info-box .title {
      font-size: 1.25rem;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .footer-info-box .title {
      font-size: 1rem;
    }
  }
  
  .footer-info-box .mail a {
    font-size: 3.75rem;
    font-weight: 700;
    color: #89715b;
    margin-top: 0.625rem;
    display: inline-block;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  @media only screen and (max-width: 767px) {
    .footer-info-box .mail a {
      font-size: 2.5rem;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .footer-info-box .mail a {
      font-size: 1.25rem;
    }
  }
  
  .footer-info-box .mail a:hover {
    color: #fff;
  }
  
  .footer-copyright-social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 5rem;
  }
  
  @media only screen and (max-width: 767px) {
    .footer-copyright-social {
      display: block;
      text-align: center;
    }
  }
  
  .footer-copyright {
    padding-bottom: 1.25rem;
  }
  
  .footer-copyright span {
    color: #fff;
  }
  
  .footer-copyright i {
    color: #da2929;
  }
  
  .footer-copyright a {
    color: #fff;
  }
  
  .footer-copyright a:hover {
    color: #89715b;
  }
  
  .footer-social {
    padding-bottom: 1.25rem;
  }
  
  .footer-social .social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  @media only screen and (max-width: 767px) {
    .footer-social .social {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
    }
  }
  
  .footer-social .social li + li {
    margin-left: 2.1875rem;
  }
  
  .footer-social .social li a {
    font-size: 1rem;
  }
  
  @media only screen and (min-width: 760.5rem) and (max-width: 991px), only screen and (max-width: 575px) {
    .footer-social .social li a {
      font-size: 0.875rem;
    }
  }
  
  .footer-social .social li a.twitter {
    color: #00ccff;
  }
  
  .footer-social .social li a.behance {
    color: #0066ff;
  }
  
  .footer-social .social li a.dribbble {
    color: #ff0066;
  }
  
  .footer-social .social li a.github {
    color: #ffffff;
  }
  
  .back-to-top {
    background-color: #090909;
    color: #fff;
    width: 3.125rem;
    height: 3.125rem;
    line-height: 3.125rem;
    display: none;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    position: fixed;
    right: 3.125rem;
    bottom: 3.125rem;
    font-size: 1.5rem;
    z-index: 28;
    -webkit-box-shadow: 0 0 7px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 7px rgba(255, 255, 255, 0.1);
  }
  
  @media only screen and (max-width: 575px) {
    .back-to-top {
      right: 1.25rem;
      bottom: 1.25rem;
      width: 2.1875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      font-size: 1.25rem;
    }
  }
  
  .back-to-top:hover {
    background-color: #89715b;
    color: #fff;
  }
  
.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  .footer-logo-box {
    text-align: start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    width: 25%;
    
  }
  .footer-logo-box a img {
    width: 50%;
  }
  .footer-logo-box p {
   color: white;
  }

  .footer-copyright-social{
    margin: 0 5rem;
    border-top: 1px solid white;
  }

  .footer-copyright{
   text-align: left;
   color: rgb(135, 130, 130);
  }
  .footer-social{
   text-align: right;
   color: rgb(135, 130, 130);
  }
  .osicrew_link{
    color: rgb(135, 130, 130);
    text-decoration: none;
  }
  .osicrew_link:hover{
    color:red;
  }


.footer-logo-box .social-box{
  display: flex;
  align-items: center;
  flex-direction: row; 
  margin-bottom: 1rem;
  }
  
  .footer-logo-box .social-box svg{
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  }
  .footer-logo-box .social-box svg:hover{
  color: #3b82f6;
  }

  .container-fluid .footer-info-box.d-flex.justify-content-between{
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 5rem;
  }


 