/* .container.banner{
height: 100vh;
} */

.container.banner .row.first_row{
    height: 40vh;
}

.container.banner .row.first_row .col-12{
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    padding-bottom: 3vh;
}

.container.banner .row.second_row{
    height: 60vh;
}

@media only screen and (min-height: 500px ) and (orientation: landscape) {
 
    .container.banner .row.second_row{
        height: 110vh;
    }
    
}
@media only screen and (min-height: 750px ) and (orientation: landscape) {
 
    .container.banner .row.second_row{
        height: 80vh;
    }
    
}
@media only screen and (min-height: 1000px ) and (orientation: landscape) {
 
    .container.banner .row.second_row{
        height: 60vh;
    }
    
}
@media only screen and (min-height: 1500px ) and (orientation: landscape) {
 
    .container.banner .row.second_row{
        height: 40vh;
    }
    
}


.container.banner .row.second_row .col-12 img{
width: 100%;
}

.about-page .container .about-title{
    margin-top: 2rem;
}

.about-page .container .about-title h1, .about-page .container .about-title h2{
    color: white;
    text-align: center;
    font-size: 2.5rem;
    line-height: 3.375rem;

}
.about-page .container .about-title_blue h3{
margin-top: 1rem;
margin-bottom: 2rem;
text-align: start;
}
.about-page .container .about-title_blue h3{
    color: #3B82F6;
    font-size: 2.5rem;
    line-height: 2.5625rem;
}
.about-page .container .about-content p{
    color: white;
    font-size: 1.5rem;    
    text-align: start;
}

.about-page .about-title_blue{
    margin: 4rem 0;
}
.about-content{
    margin-bottom: 6rem;
}

.container.banner.page-details{
    max-width: 100vw;
}

.container.banner .row.first_row{
    height: 40vh;
}
.container.banner .row.first_row h1{
    font-size: 3.5rem;
}

.container.banner{
    max-width: 100vw;
}

.container.banner.about-us  .row.second_row{
    height: auto;
}


@media only screen and (orientation: portrait) {
    .container.banner .row.first_row {
        height: 25vh !important;
    }
    .container.banner .row.second_row {
        height: 30vh !important;
    }
    .application-number {
        left: 0;
    }
}
