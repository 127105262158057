/* .container.banner {
height: 100vh;
} */

.page-details .row.second_row .col-12 p.description{
    font-size: 1.5rem;
    color: white;
    height: max-content;
    text-align: start;
    padding: 1rem 5rem 1rem 5rem;
}
.page-details .row.second_row .col-12 p.description{
    text-align: start;
    font-size: 1.5rem;
    color: white;
    height: max-content;
;
}
.page-details .row.second_row .col-12{
    max-height: 50vh;
    overflow: hidden;
}