.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .container {
    text-align: center;
  }
  
  .not-found-content h1 {
    font-size: 10rem;
    font-weight: bold;
    color: #343a40;
  }
  
  .not-found-content h2 {
    font-size: 2rem;
    margin-top: 1rem;
    color: #6c757d;
  }
  
  .not-found-content p {
    font-size: 1rem;
    margin-top: 1rem;
    color: #6c757d;
  }
  
  .home-buttonnn {
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-buttonnnn:hover {
    background-color: #0056b3;
  }
  