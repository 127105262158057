.container-slogan.container-fluid {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
}

.container-slogan .text {
    font-size: 4rem;
    color: white;
    overflow: hidden;
}

.container-slogan .text span {
    color: #3a7cf3;
    font-size: 4.4rem;
}

.container-slogan .mascot {
    margin-right: 2rem;
}
@media screen and (max-width: 700px){
    .container-slogan .mascot {
        max-width: 14rem;
    }
}
@media screen and (max-width: 500px){
    .container-slogan .mascot {
        max-width: 8rem;
    }
    .wordCarousel.text, .container-slogan .text span, .container-slogan .wordCarousel div li{
        font-size: 3.5rem;
    }
}
@media screen and (max-width: 400px){
    .container-slogan .mascot {
        max-width: 5rem;
    }
    .wordCarousel.text, .container-slogan .text span, .container-slogan .wordCarousel div li{
        font-size: 3rem;
    }
}


.wordCarousel {
    font-size: 36px;
    font-weight: 100;
    color: #eee;
  }
  .wordCarousel div {
    overflow: hidden;
    position: relative;
    float: right;
    height: 65px;
    
  }
  .wordCarousel div li {
    height: 45px;
    margin-bottom: 45px;
    display: block;
    color: #3a7cf3;
    font-size: 4.4rem;
  }
  
  .flip2 {
    -webkit-animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
            animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
  }
  
  ul.flip2 {
    padding-left: 0;
  }
  
  @-webkit-keyframes flip2 {
    0% {
      margin-top: -180px;
    }
    5% {
      margin-top: -90px;
    }
    50% {
      margin-top: -90px;
    }
    55% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -180px;
    }
  }
  
  @keyframes flip2 {
    0% {
      margin-top: -180px;
    }
    5% {
      margin-top: -90px;
    }
    50% {
      margin-top: -90px;
    }
    55% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -180px;
    }
  }
  
