/* @font-face {
    font-family: 'BebasNeue-Regular';
    src: url('/assets/fonts/BebasNeue-Regular.eot');
    src: url('/assets/fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
             url('/assets/fonts/BebasNeue-Regular.woff2') format('woff2'),
             url('/assets/fonts/BebasNeue-Regular.woff') format('woff'),
             url('/assets/fonts/BebasNeue-Regular.ttf') format('truetype'),
             url('/assets/fonts/BebasNeue-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
} */
@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./assets/fonts/bebas_neue_pro_regular.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.9)),
    url("./assets/bg_img.png") no-repeat center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-size: 1rem;
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
  .container.banner.not-image{
    height: 40vh !important;
  
  }
}


@media screen and (min-width: 1032px) and (max-width: 1600px){
  html {
    font-size: 0.9rem;
  }
.container.banner .row.first_row{
  height: 30vh;
}




div.about-us .image-frame-box-image-text-box .image-frame-box .border-bottom-right{
  bottom:-5vh;
  right:-5vh;
}
div.about-us .image-frame-box-image-text-box .image-frame-box .border-top-left{
  top:-5vh;
  left:-5vh;
}


}

@media screen and (min-width: 601px) and (max-width: 1032px) {
  html {
    font-size: 0.625rem;
  }
  .container-fluid.banner{
    height: 25vh !important;
  }
  .container-fluid.banner .row.first_row{
    height: 20vh;
  }


}

@media screen and (max-width: 600px) {
  html {
    font-size: 0.5rem;
  }
  .icon-box {
    justify-content: center;
    flex-direction: column;
  }

  .icon-box .main-border-div {
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .image-frame-box-image-text-box {
    flex-direction: column;
  }
  .image-frame-box-image-text-box .image-text-box{
    max-width: 100%;
  }
  .col-6{
    width: 100% !important;
  }
  .image-frame-box-image-text-box{
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
  }
  .image-frame-box-image-text-box .image-text-box .image-text-box-title{
    margin-top: 2rem;
  }
  .footer-info-box.d-flex.justify-content-between{
    flex-wrap: wrap;
  }
  .footer-communication-box{width: 50%;}

  .footer-applications-box{width: 50%;
    display: flex;
    align-items: flex-end;}

  .footer-pages-box{width: 50%;}

  .footer-logo-box{width: 50%;
    justify-content: flex-start
  }
  .footer-copyright-social{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 0;
  }
  .footer-social{
    width: 30%;
  }
  .footer-copyright{
    width: 50%;
  }
.container-fluid.banner .row.first_row {
height: 16vh;
}
.container-fluid.banner {
height: 37vh !important;
}

.container-fluid.banner{
  height: 13vh !important;
}

.icon-box {
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .border-bottom-right{
  bottom:-5vh;
  right:-5vh;
}
div.about-us .image-frame-box-image-text-box .image-frame-box .border-top-left{
  top:-5vh;
  left:-5vh;
}

div.about-us .image-frame-box-image-text-box .image-frame-box .image-image{
  width: 12rem;
}

}

